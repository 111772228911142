.home-page {
  opacity: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  pointer-events: all;

  .box {
    z-index: 1;
    pointer-events: all;
    max-width: 500px;
    width: 100%;
    margin-top: 10vh;
    margin-left: 5vw;
    margin-right: 5vw;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    backdrop-filter: blur(25px);
    padding: 25px 30px;
    color: white;
    box-shadow: 3px 5px 25px rgba(0, 0, 0, 0.1);
    border: 0.5px solid rgba(0, 0, 0, 0.15);

    .back-button {
      a {
        color: white;
        font-size: 1.3rem;

        .link-arrow {
          position: absolute;
          margin-top: -2.3px;
          font-size: 1.5rem;
        }
      }
    }

    .title {
      font-size: 3rem;
      padding-top: 20px;
      letter-spacing: 1px;
    }
    .subtitle{
      font-size: 2rem;
      padding-top: 10px;
      color: #aaa;
    }

    p {
      font-size: 1.2rem;
      padding-top: 10px;
      padding-bottom: 10px;
      line-height: 170%;
    }

    .sign-inup-nav {
      font-size: 1.2rem;
      color: #bbb;
      padding: 15px 10%;
      font-weight: 300;

      a {
        color: white;
        font-weight: 500;
      }
    }
  }

  @keyframes showPage {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}