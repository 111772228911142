$sub-color: #999;
$main-color: white;

@mixin shrinkLabel {
  top: -15px;
  font-size: 1rem;
  color: $main-color;
}

.group-oval.standardForm {
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;

  .form-input {
    font: inherit;
    background: none;
    background-color: rgba(0,0,0,0.1);
    color: $main-color;
    font-size: 1.2rem;

    font-weight: 300;
    padding: 10px 11px;
    display: block;
    width: 100%;
    border: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.15);
    margin: 25px 0 0 0;
    border-radius: 20px;

    &:focus {
      outline: none;
    }

    &:focus~.form-input-label-off {
      display: none;
    }
  }

  textarea {
    resize: vertical;
    font: inherit;
    min-height: 130px;
    font-size: 1.2rem;


    &:focus~.bar:before {
      width: 100%;
    }
  }

  input {
    font: inherit;
    font-size: 1.2rem;
  }

  input[type='password'] {
    letter-spacing: 0.3em;
    font-weight: 400;
  }

  
  .form-input-label {
    color: $sub-color;
    font-size: 1.2rem;
    font-weight: 300;
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 11px;
    transition: 300ms ease all;
    
    &.shrink {
      color: $main-color;
      @include shrinkLabel();
    }
  }
}

.group.textarea {
  margin-top: 30px;
  .form-input-label {
    top: 95px;
    color: white;

    &.shrink {
      color: $main-color;
      top: -14px;
    }
  }
}