.home-page-auth {
  .top-nav {
    z-index: 1;
    pointer-events: all;
    display: flex;
    position: absolute;
    color: white;
    right: 0px;
    margin: 6px;

    .topNavItem {
      font-weight: 600 !important;

    }

    .user {
      color: #ccc;
      margin: 7px 10px;
    }

    .signout {
      cursor: pointer;
      background: rgba(140, 160, 255, 0.2);
      border-radius: 5px;
      backdrop-filter: blur(25px);
      padding: 7px 10px;
      box-shadow: 3px 5px 25px rgba(0, 0, 0, 0.1);

      &:hover {
        background: rgba(140, 160, 255, 0.25);
      }

      &:active {
        background: rgba(140, 160, 255, 0.3);
      }
    }
  }
}