.game-page {
  opacity: 1;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: all;
  canvas {
    background: black;
    width: 500px;
    height: 100vh;
  }
}