.customButtonShadow,
.customButtonShadow[type="submit"],
.customButtonShadow[type="reset"] {
  color: inherit;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;
  display: flex;
  flex-direction: row;
  margin: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 75px;
  background: rgba(0, 0, 0, 0.2);
  padding: 9.5px 45px;
  font-size: 1rem;
  font-weight: 400;
  user-select: none;
  align-items: center;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.05);

  &:hover {
    background-color: rgba(0, 0, 0, 0.25);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.3);
  }

  img {
    position: absolute;
    right: 4%;
  }
}

.customButtonShadow:disabled,
.customButtonShadow[disabled] {
  color: #aaa;
  cursor: wait;
  background-color: rgba(0, 0, 0, 0.1);

}