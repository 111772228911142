.sign-upin-form {
  padding-right: 10%;
  padding-left: 10%;
  padding-bottom: 0px;
  margin-top: 30px;

  input {
    padding-right: 5%;
    padding-left: 5%;
  }

  .group-oval.standardForm {
    margin-top: 15px;
  }

  .customButtonShadow {
    margin-top: 15px !important;
    width: 100%;
    max-width: 120px;
    margin-right: 0px !important;
    justify-content: center;
    justify-self: right;
  }

  p {
    margin: 20px auto 0px 7px;
    font-size: 1.3rem;
    text-align: left;
    max-width: 300px;
    font-weight: 300;
    color: #bbb;
    line-height: 150%;
    a {
      font-weight: 500;
      color: white;
    }
  }
}