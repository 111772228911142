.background-mock {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 0;
  top: 0px;
  pointer-events: all;

  .game-randomly-mock {
    height: 100vh;
  }
}