.game-launcher {
  padding-top: 50px;
  padding-bottom: 50px;
  min-height: 330px;
  .customButtonShadow {
    font-size: 2rem;
  }

  .queue-tile {
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    max-width: 250px;
    margin: auto;
    margin-top: 40px;
    font-size: 2rem;
    border-radius: 5px;
    background: rgba(140, 160, 255, 0.2);
    backdrop-filter: blur(25px);
    padding: 5px 20px 20px 20px;
    box-shadow: 3px 5px 25px rgba(0, 0, 0, 0.1);
    line-height: 100%;
    letter-spacing: 2px;
    position: relative;
    .cancel-btn{
      width: 20px;
      padding-left: 2px;
      text-align: center;
      border-radius: 20px;
      line-height: 20px;
      height: 20px;
      color: white;
      position: absolute;
      font-size: 2.8rem;
      right: 4px;
      top: 4px;
      background: rgba(0, 0, 0, 0.2);
      font-weight: 300;
      cursor: pointer;
      &:hover{
        background: rgba(0, 0, 0, 0.4);
      }
      &:active{
        background: rgba(0, 0, 0, 0.6);
        color: #aaa;
      }
    }

    .queue-title {
      color: rgba(140, 160, 255, 1);
      font-size: 1.2rem;
      font-weight: 600;
      padding-top: 8px;
      text-transform: uppercase;
      padding-bottom: 15px;
      letter-spacing: 1px;
      text-align: center;
    }


    .row {
      display: flex;

      .col50 {
        width: 50%;
        text-align: center;

        .subtitle {
          font-size: 1.2rem;
          font-weight: 400;
          color: #aaa;
          letter-spacing: 1px;
        }

        .subContent {
          padding-top: 8px;
          padding-bottom: 3px;
          font-size: 2.8rem;
          font-weight: 300;
          letter-spacing: 3px;
        }
      }
    }
  }
}