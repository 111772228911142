::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0.05);
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

::-webkit-scrollbar-track {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.15);
  outline: 1px solid slategrey;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.policy-page {
  max-height: 90vh;
  


  .box {
    overflow-y: scroll;
    max-height: 100vh;
    max-width: 700px;
    
  }

  .title {
    margin-top: 30px;
    padding-bottom: 20px;
  }

  h4 {
    padding-top: 40px;
    font-size: 2.2rem;
    font-weight: 400;
    padding-bottom: 10px;
  }
  p{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    color: #ddd;
  }
  ul{
    font-size: 1.2rem;
    padding-top: 5px;
    padding-bottom: 5px;
    li{
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
}