a {
  text-decoration: none;
  color: black;
}

.toast {
  animation-duration: 0.3s !important;
  font-size: 1.3rem;
  margin-right: 20px;
  border-radius: 3px !important;
  padding: 7px 45px !important;
  color: white !important;
  border: 1px solid black !important;
}

.app-pages {
  min-height: calc(100vh + 1px - 100px);
  z-index: 1;
  position: relative;
  pointer-events: none;

  @media only screen and (min-width: 500px) and (max-height: 420px) {
    padding-top: 50px;
    padding-bottom: 30px;
  }
}

.toasts-container {
  .toast {
    border-radius: 5px !important;
    color: white !important;
    background-color: rgba(0, 0, 0, 0.1) !important;
    font-size: 1.2rem !important;
    border-width: 0.5px !important;
    backdrop-filter: blur(25px) !important;
    box-shadow: 3px 5px 25px rgba(0, 0, 0, 0.1) !important;
  }

  .toast.toast-success {
    background-color: rgba(0, 0, 0, 0.1) !important;
    border-color: rgba(0, 255, 0, 0.3) !important;
    ;
  }

  .toast.toast-error {
    background-color: rgba(0, 0, 0, 0.1) !important;
    border-color: rgba(255, 0, 0, 0.3) !important;
    ;
  }
}

* {
  user-select: none !important;
}